import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SalesOrderitem} from '../sales-orderitem';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {SalesOrderService} from '../sales-order.service';
import {SalesOrder} from '../sales-order';
import {OrderDetailOpmDialogComponent} from './order-detail-opm-dialog/order-detail-opm-dialog.component';
import {LocationService} from '../../_services/location.service';
import {ScanOrderItemDialogComponent} from './scan-order-item-dialog/scan-order-item-dialog.component';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {BarcodeScannerDialogComponent} from './barcode-scanner-dialog/barcode-scanner-dialog.component';
import {SalesOrderCompleteDialogComponent} from '../order-complete/order-complete/sales-order-complete-dialog.component';
import {PrintingDialogComponent} from '../order-item-overview/printing-dialog/printing-dialog.component';
import {BeepService} from '../../_services/beep.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  hideChecked = false;
  order: SalesOrder;
  orderItemDataSource = new MatTableDataSource<SalesOrderitem>();
  displayedColumns: string[] = ['checked', 'deliverAmount', 'articleName', 'location', 'vml', 'vmh', 'mergedBoxes', 'neededLabels', 'remarks', 'search'];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loadingItemSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingItem$ = this.loadingItemSubject.asObservable();
  faBarcode = faBarcode;
  totalVml: number;
  totalVmh: number;
  totalMerged: number;
  totalLabels: number;
  subscriptions = new Subscription();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private checklistService: SalesOrderService,
    private snackBar: MatSnackBar,
    private _location: Location,
    private _dialog: MatDialog,
    private locationService: LocationService,
    private _router: Router,
    private beepService: BeepService
  ) {
    this.hideChecked = this.checklistService.getHideChecked();
  }

  ngOnInit() {
    this.subscriptions.add(this.route.data.subscribe(data => this.setupOrder(data.checklistOrder)));
    this.subscriptions.add(this.locationService.observeSelectedLocation()
      .subscribe(() => {
        if (this.order) {
          this.refreshTable();
        }
      }));
  }

  ngAfterViewInit() {
    this.orderItemDataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openScanDialog(item?: SalesOrderitem, order?: SalesOrder) {
    const ref = this._dialog.open(ScanOrderItemDialogComponent, {data: {order: order, item: item}});
    this.subscriptions.add(ref.afterClosed().subscribe(_ => {
      this.refreshSalesOrderItems();
    }));
  }

  openBarcodeScanDialog(item?: SalesOrderitem, order?: SalesOrder) {
    const ref = this._dialog.open(BarcodeScannerDialogComponent, {data: {order: order ?? this.order, item: item}});
    this.subscriptions.add(ref.afterClosed().subscribe(_ => {
      this.refreshSalesOrderItems();
    }));
  }

  hideCheckChanged() {
    this.checklistService.setHideChecked(this.hideChecked);
    this.refreshTable();
  }

  checkRestLaag(item: SalesOrderitem) {
    if (item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged)) {
      item.scannedVml = item.neededLabels - (item.scannedVmh + item.scannedMerged);
      this.updateOrderItem(item);
    }
  }

  checkRestHoog(item: SalesOrderitem) {
    if (item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged)) {
      item.scannedVmh = item.neededLabels - (item.scannedVml + item.scannedMerged);
      this.updateOrderItem(item);
    }
  }

  checkRestSamen(item: SalesOrderitem) {
    if (item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged)) {
      item.scannedMerged = item.neededLabels - (item.scannedVml + item.scannedVmh);
      this.updateOrderItem(item);
    }
  }

  vmlChanged(newValue: number, item: SalesOrderitem) {
    item.scannedVml = newValue;
    this.updateOrderItem(item);
  }

  vmhChanged(newValue: number, item: SalesOrderitem) {
    item.scannedVmh = newValue;
    this.updateOrderItem(item);
  }

  mergedBoxesChanged(newValue: number, item: SalesOrderitem) {
    item.scannedMerged = newValue;
    this.updateOrderItem(item);
  }

  openDialog(item: SalesOrderitem): void {
    const dialogRef = this._dialog.open(OrderDetailOpmDialogComponent, {
      width: '250px',
      data: item
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateOrderItem(result);
      }
    }));
  }

  openPrintingDialog(salesOrderItem: SalesOrderitem) {
    this._dialog.open(PrintingDialogComponent, {
      width: '500px',
      height: '450px',
      data: salesOrderItem
    });
  }

  private setupOrder(order: SalesOrder) {
    this.order = order;
    this.orderItemDataSource.data = this.order.items;
    this.refreshTable();
    const itemSleutel = +this.route.snapshot.queryParamMap.get('orderItemId');
    const device = this.route.snapshot.queryParamMap.get('device');
    if (itemSleutel) {
      const orderIndex = this.order.items.findIndex(i => i.id === itemSleutel);
      if (orderIndex >= 0) {
        if (device === 'scanner') {
          this.openBarcodeScanDialog(this.order.items[orderIndex], order);
        } else {
          this.openScanDialog(this.order.items[orderIndex], order);
        }
      }
    }
  }

  private async updateOrderItem(item: SalesOrderitem) {
    this.loadingItemSubject.next(true);
    try {
      item.allLabelsScanned = item.scannedVml + item.scannedVmh + item.scannedMerged === item.neededLabels;
      await this.checklistService.updateSalesOrderItem(item).toPromise();
      if (this.orderItemDataSource.data.every(salesOrderItem => salesOrderItem.allLabelsScanned)) {
        this.openOrderCompleteDialog();
      }
    } catch (error) {
      console.error('Unable to save CheckListItem:\n' + error);
      this.snackBar.open('Fout bij opslaan! Probeer het nog eens', '', {duration: 1000});
    } finally {
      this.refreshTable();
      this.loadingItemSubject.next(false);
    }
  }

  private async refreshSalesOrderItems() {
    this.loadingItemSubject.next(true);
    try {
      this.order.items = await this.checklistService.getSalesOrderItemsFoSalesOrderId(this.order.id).toPromise();
    } catch (error) {
      console.error('Unable to refresh SalesOrderItems:\n' + error);
      this.snackBar.open('Fout bij ophalen van de verkoopregels! Probeer het nog eens', '', {duration: 1000});
    } finally {
      this.refreshTable();
      this.loadingItemSubject.next(false);
    }
  }

  private refreshTable() {
    const itemsByLocation = this.order.items.filter(item => this.locationService.isBedNrFromSelectedLocation(item.location));
    this.orderItemDataSource.data = itemsByLocation.filter(item => !(this.hideChecked && item.allLabelsScanned));
    this.calculateTotals(itemsByLocation);
  }

  calculateTotals(items: SalesOrderitem[]) {
    this.totalVml = 0;
    this.totalVmh = 0;
    this.totalMerged = 0;
    this.totalLabels = 0;

    items.forEach(i => {
      this.totalVml += i.scannedVml;
      this.totalVmh += i.scannedVmh;
      this.totalMerged += i.scannedMerged;
      this.totalLabels += i.neededLabels;
    });
  }

  openOrderCompleteDialog() {
    this.beepService.completed();
    this._dialog.open(SalesOrderCompleteDialogComponent, {
      data: {
        items: this.orderItemDataSource.data,
        order: this.order
      }
    });
  }
}
