<mat-form-field id="search-field" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>Zoek op naam of code</mat-label>
  <input matInput #searchInput type="search" autofocus placeholder="Naam of code" [(ngModel)]="searchTerm"
    (keyup)="searchTermSubject.next(searchTerm)" (keyup.enter)="searchTermSubject.next(searchTerm)" autocomplete="off">
  <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
    <mat-icon>&#xE5CD;</mat-icon>
  </button>
</mat-form-field>

<div #scrollContainer mat-elevation-z8 id="scroll-container">
  <div class="loading-shade" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <table mat-table #table [dataSource]="dataSource" class="table" matSort matSortActive="naam" matSortDirection="asc"
    [hidden]="dataSource.data.length === 0">
    <!-- Naam Column -->
    <ng-container matColumnDef="naam">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
      <td mat-cell *matCellDef="let row">{{ row.plantName  }}</td>
    </ng-container>
    <!-- Bednr Column -->
    <ng-container matColumnDef="bednr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bednr</th>
      <td mat-cell *matCellDef="let row">{{ row.location }}</td>
    </ng-container>
    <!-- rest Column -->
    <ng-container matColumnDef="rest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rest</th>
      <td mat-cell *matCellDef="let row">{{ row.rest }}</td>
    </ng-container>
    <!-- plant datum Column -->
    <ng-container matColumnDef="plant_dat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Plant datum</th>
      <td mat-cell *matCellDef="let row">{{ row.plantingDate }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/voorraad/vollegrond/{{row.id}}"></tr>
  </table>
  <div class="no-result-label" *ngIf="noResultsFound">Geen resultaten</div>
</div>
