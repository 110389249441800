  <mat-form-field id="search-field" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Zoek op naam of code</mat-label>
    <input matInput #searchInput type="search" autofocus placeholder="Naam of code" [(ngModel)]="searchTerm"
      (keyup)="searchTermSubject.next(searchTerm)" (keyup.enter)="searchTermSubject.next(searchTerm)"
      autocomplete="off">
    <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
      <mat-icon>&#xE5CD;</mat-icon>
    </button>
  </mat-form-field>

  <div #scrollContainer mat-elevation-z8 class="scroll-container">
    <div class="loading-shade" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-table id="scrollToContainer" [hidden]="dataSource.filteredData.length <= 0" [dataSource]="dataSource" matSort
      matSortActive="plantName" matSortDirection="asc">
      <!-- Naam Column -->
      <ng-container matColumnDef="plantName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Naam</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.plantName }}</mat-cell>
      </ng-container>
      <!-- Bednr Column -->
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef  mat-sort-header>Bednr</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{ row.location }}</mat-cell>
      </ng-container>
      <!-- Aant stek origineel Column -->
      <ng-container matColumnDef="originalAmount">
        <mat-header-cell *matHeaderCellDef mat-sort-header >Aantal</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{ row.originalAmount }}</mat-cell>
      </ng-container>
      <!-- Aant stek rest Column -->
      <ng-container matColumnDef="rest">
        <mat-header-cell *matHeaderCellDef mat-sort-header >Rest</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{ row.rest }}</mat-cell>
      </ng-container>
      <!-- Date stek Column -->
      <ng-container matColumnDef="pottedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Datum gestekt</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.pottedDate | date:"dd-MM-yyyy" }}</mat-cell>
      </ng-container>
      <!-- Ready-For-Potting Column -->
      <ng-container matColumnDef="readyForPotting">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Oppot-baar</mat-header-cell>
        <mat-cell *matCellDef="let row" class="checkbox-cell">
          <mat-checkbox [ngModel]="row.readyForPotting" [disabled]="true"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/voorraad/stek/{{row.id}}"></mat-row>
    </mat-table>
    <div *ngIf="noResultsFound">Geen resultaten</div>
  </div>
  <button mat-fab id="add-pot-stock-button" class="add-pot-stock-button" color="primary" (click)="openAddStockDialog()">
    <mat-icon aria-label="Add">add</mat-icon>
  </button>
