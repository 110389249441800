import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ScannerComponent} from '../../../common/scanner/scanner.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SalesOrderService} from '../../sales-order.service';
import {BeepService} from '../../../_services/beep.service';
import {QuaggaJSResultObject} from '@ericblade/quagga2';
import {SalesOrderitem} from '../../sales-orderitem';
import {SalesOrder} from '../../sales-order';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {OrderDetailOpmDialogComponent} from '../order-detail-opm-dialog/order-detail-opm-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {ChangeBonDialogComponent} from '../change-bon-dialog/change-bon-dialog.component';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

export interface ScanDialogResult {
  orderId: number;
  itemSleutel: number;
}

@Component({
  selector: 'app-scan-order-item-dialog',
  templateUrl: './scan-order-item-dialog.component.html',
  styleUrls: ['./scan-order-item-dialog.component.scss']
})
export class ScanOrderItemDialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild(ScannerComponent) scanner: ScannerComponent;
  scannerStarted: boolean;
  faBarcode = faBarcode;

  item: SalesOrderitem;
  previousItem: SalesOrderitem;
  order: SalesOrder;
  subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ScanDialogResult>,
    private checkListService: SalesOrderService,
    private beepService: BeepService,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _router: Router) {
    this.order = data.order;
    this.item = data.item;
  }

  ngAfterViewInit(): void {
    this.scanner.start();
  }

  onStarted(started: boolean) {
    if (started) {
      if (this.item) {
        this.scanner.pause();
      }
      this.scannerStarted = started;
    }
  }

  onScanSuccess(result: QuaggaJSResultObject) {
    this.beepService.plop();
    window.navigator.vibrate(200);
    this.scanner.pause();
    const itemSleutel = +result.codeResult.code;
    const index = this.order.items.findIndex(i => i.id === itemSleutel);
    if (index < 0) {
      this.checkListService.findSalesOrderIdByItemSleutel(itemSleutel).subscribe({
        next: id => this.askToSwitchBon(id, itemSleutel),
        error: (error: any) => this.showScanError(error)
      });
    } else {
      this.setItem(this.order.items[index]);
    }
  }

  ngOnDestroy() {
    this.scanner.stop();
    this.subscriptions.unsubscribe();
  }

  askToSwitchBon(orderId: number, itemSleutel: number) {
    this.checkListService.getSalesOrderForCheckList(orderId).subscribe(order => {
      const dialogRef = this._dialog.open(ChangeBonDialogComponent, {data: {order: order}});
      this.subscriptions.add(dialogRef.afterClosed().subscribe(shouldNavigate => {
        if (shouldNavigate) {
          this.dialogRef.close({orderId, itemSleutel});
        } else {
          this.setPreviousItem();
        }
      }));
    });
  }

  startScan() {
    this.setItem(undefined);
    this.scanner.start();
  }

  setPreviousItem() {
    this.setItem(this.previousItem);
  }

  checkRestLaag() {
    if (this.item.neededLabels > (this.item.scannedVml + this.item.scannedVmh + this.item.scannedMerged)) {
      this.item.scannedVml = this.item.scannedMerged - (this.item.scannedVmh + this.item.scannedMerged);
      this.updateOrderItem().then(() => {
        this.startScan();
      });
    }
  }

  checkRestHoog() {
    if (this.item.neededLabels > (this.item.scannedVml + this.item.scannedVmh + this.item.neededLabels)) {
      this.item.scannedVmh = this.item.neededLabels - (this.item.scannedVmh + this.item.scannedMerged);
      this.updateOrderItem().then(() => {
        this.startScan();
      });
    }
  }

  checkRestSamen() {
    if (this.item.neededLabels > (this.item.scannedVml + this.item.scannedVmh + this.item.scannedMerged)) {
      this.item.scannedMerged = this.item.neededLabels - (this.item.scannedVml + this.item.scannedVmh);
      this.updateOrderItem().then(() => {
        this.startScan();
      });
    }
  }

  vmlChanged(newValue: number, item: SalesOrderitem) {
    item.scannedVml = newValue;
    this.updateOrderItem();
  }

  vmhChanged(newValue: number, item: SalesOrderitem) {
    item.scannedVmh = newValue;
    this.updateOrderItem();
  }

  mergedBoxesChanged(newValue: number, item: SalesOrderitem) {
    item.scannedMerged = newValue;
    this.updateOrderItem();
  }

  openDialog(item: SalesOrderitem): void {
    const dialogRef = this._dialog.open(OrderDetailOpmDialogComponent, {
      width: '250px',
      data: item
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateOrderItem();
      }
    }));
  }

  private checkIfComplete() {
    this.item.allLabelsScanned = this.item.scannedVml + this.item.scannedVmh + this.item.scannedMerged === this.item.neededLabels;
  }

  private async updateOrderItem() {
    try {
      this.checkIfComplete();
      const updatedItem = await this.checkListService.updateSalesOrderItem(this.item).toPromise();
      this.item.version = updatedItem.version;
    } catch (error) {
      console.error('Unable to save CheckListItem:\n' + error);
      this._toastr.error('Fout bij opslaan! Probeer het nog eens');
    }
  }

  private showScanError(error?: any) {
    this._toastr.error('Kon geen bon of regel vinden met deze scan. Is het label vies? Probeer het nog eens');
    this.scanner.start();
  }

  private setItem(item: SalesOrderitem) {
    if (this.item) {
      this.previousItem = this.item;
    }
    this.item = item;
  }
}
