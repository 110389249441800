import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {SalesOrder} from '../../sales-order';
import {SalesOrderitem} from '../../sales-orderitem';


@Component({
  selector: 'app-order-complete',
  templateUrl: './sales-order-complete-dialog.component.html',
  styleUrls: ['./sales-order-complete-dialog.component.scss']
})
export class SalesOrderCompleteDialogComponent implements OnInit {
  items: SalesOrderitem[];
  order: SalesOrder;
  customer = '';
  totalvml = 0;
  totalvmh = 0;
  totalMerged = 0;
  faCheck = faCheck;

  constructor(
    private _dialog: MatDialog,
    public dialogRef: MatDialogRef<void>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.items = data.items;
    this.order = data.order;
  }

  ngOnInit(): void {
    this.calculateTotals();
  }

  calculateTotals() {
    this.items.forEach(item => {
      this.totalvmh += item.scannedVmh;
      this.totalvml += item.scannedVml;
      this.totalMerged += item.scannedMerged;
    });
  }

  backToOrderOverview() {
    window.location.replace('/afchecklijst');
  }

  close() {
    this.dialogRef.close();
  }
}
