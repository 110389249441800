import {Injectable} from '@angular/core';
import {BaseService} from '../common/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SalesOrder} from './sales-order';
import {SalesOrderitem} from './sales-orderitem';
import {SalesOrderTotalDTO} from './register-totals/SalesOrderTotalDTO';
import {SummedSalesOrder} from './register-totals/SummedSalesOrder';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService extends BaseService {

  private url = this.baseUrl + '/salesorder';

  private hideChecked = false;

  getSalesOrder(orderId: number): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.url + '/' + orderId);
  }

  getSalesOrderForCheckList(orderId: number): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.url + '/low-tax/' + orderId);
  }

  findSalesOrderIdByItemSleutel(sleutel: number): Observable<number> {
    return this.http.get<number>(`${this.url}/salesorderitem/${sleutel}` );
  }

  findSalesOrderItem(sleutel: number): Observable<SalesOrderitem> {
    return this.http.get<SalesOrderitem>(this.baseUrl + `/salesorderitems/${sleutel}`);
  }

  getSalesOrdersLowTax(): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(this.url + '/low-tax');
  }

  getSalesOrdersForPakdag(): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(this.url + '/pakdag');
  }

  getSalesOrderItemsFoSalesOrderId(salesOrderId: number): Observable<SalesOrderitem[]> {
    return this.http.get<SalesOrderitem[]>(this.baseUrl + `/salesorderitems/${salesOrderId}/salesorderitem`);
  }

  updateSalesOrderItem(item: SalesOrderitem): Observable<SalesOrderitem> {
    return this.http.put<SalesOrderitem>(this.baseUrl + '/salesorderitems/' + item.id, item);
  }

  downloadReport(): Observable<Blob> {
    return this.http
      .get(this.url + '/report', {responseType: 'blob'})
      .pipe(map(blob => new Blob([blob], {type: 'text/csv;charset=utf-8'})));
  }

  updateSalesOrder(order: SalesOrderTotalDTO): Observable<void> {
    return this.http.put<void>(this.url + '/order/' + order.id, order);
  }

  exportFustData(orders: SalesOrderTotalDTO[]): Observable<void> {
    return this.http.put<void>(this.url + '/fust-orders' , orders);
  }

  exportToDBase(orderIds: number[]): Observable<void> {
    return this.http.post<void>(this.url + '/export-to-dbase', orderIds);
  }

  getSummedSalesOrders(): Observable<SummedSalesOrder[]> {
    return this.http.get<SummedSalesOrder[]>(this.url + '/totals');
  }

  setHideChecked(hideChecked: boolean) {
    this.hideChecked = hideChecked;
  }

  getHideChecked(): boolean {
    return this.hideChecked;
  }

}
