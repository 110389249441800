<h1>Voorraad aanmaken</h1>
<h2>{{ data.plantName }}</h2>
<mat-dialog-content class="dialog-content-alignment">
  <div class="button-container">
    <mat-button-toggle-group class="button-group" [vertical]="false" [(ngModel)]="location">
      <mat-button-toggle value="B/nw">B/nw</mat-button-toggle>
      <mat-button-toggle value="E/nw">E/nw</mat-button-toggle>
      <mat-button-toggle value="N/nw">N/nw</mat-button-toggle>
      <mat-button-toggle value="G/nw">G/nw</mat-button-toggle>
      <mat-button-toggle value="Zb/nw">Zb/nw</mat-button-toggle>
      <mat-button-toggle value="Zn/nw">Zn/nw</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-form-field *ngIf="data.hasReservedStock">
    <input matInput type="number" placeholder="Gereserveerde Hoeveelheid" [(ngModel)]="data.totalReservedAmount"
           [disabled]="true">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" placeholder="Originele Hoeveelheid" [(ngModel)]="originalAmount">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Bed nummer" [(ngModel)]="location">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Opmerking" [(ngModel)]="remarks">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Productie datum</mat-label>
    <div class="date-picker-production-date">
      <input matInput [matDatepicker]="picker" [(ngModel)]="productionDate">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </mat-form-field>
</mat-dialog-content>


<mat-dialog-actions>
  <div class="potting-production-job-button-container">
    <button [disabled]="isLoading" mat-raised-button (click)="cancel()">
      <mat-icon aria-label="Annuleren">backspace</mat-icon>
      Annuleren
    </button>
    <button [disabled]="isDisabled() || isLoading" mat-raised-button color="primary" (click)="onSubmitClickCreateStock()">
      In voorraad boeken
      <mat-icon>archive</mat-icon>
    </button>
    <button *ngIf="allowMarkingAsProduced" [disabled]="isLoading" mat-raised-button color="primary" (click)="onSubmitClickMarkAsProduced()">
      Productie gereed, later tellen
      <mat-icon>conveyor_belt</mat-icon>
    </button>
  </div>
</mat-dialog-actions>

