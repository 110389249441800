<div>

  <mat-card class="category-card">
    <mat-card-title>Voorraad</mat-card-title>
    <mat-card-content class="category-card-content">
      <mat-card class="management-card">
        <mat-card-title>Potten</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/voorraad/potten">
            <mat-icon>search</mat-icon>Zoeken
          </button>
          <button mat-raised-button routerLink="/voorraad/potten/tellen">
            <mat-icon>visibility</mat-icon>Tellen
          </button>
          <button mat-raised-button routerLink="/voorraad/potten/prioriteiten-tellen">
            <mat-icon>visibility</mat-icon>Prioriteiten tellen
          </button>
          <button mat-raised-button routerLink="/voorraad/potten/gestuurde-telling">
            <mat-icon>visibility</mat-icon>Gestuurde telling
          </button>
        <button mat-raised-button routerLink="voorraad/potten-history">
          <mat-icon>work_history</mat-icon>Historische potvoorraad
        </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="management-card">
        <mat-card-title>Stek</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/voorraad/stek">
            <mat-icon>search</mat-icon>Zoeken
          </button>
          <button mat-raised-button routerLink="/voorraad/stek/tellen">
            <mat-icon>visibility</mat-icon>Tellen
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="management-card">
        <mat-card-title>Vollegrond</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/voorraad/vollegrond">
            <mat-icon>search</mat-icon>Zoeken
          </button>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>

  <mat-card class="category-card">
    <mat-card-title>Verkoop</mat-card-title>
    <mat-card-content class="category-card-content">

      <mat-card class="management-card">
        <mat-card-title>Afchecken</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/afchecklijst">
            <mat-icon>list</mat-icon>Bonnen
          </button>
          <button mat-raised-button routerLink="/afchecklijst/regels">
            <mat-icon>check</mat-icon>Regels
          </button>
          <button mat-raised-button routerLink="/afchecklijst/totalen">
            <mat-icon>save</mat-icon>Registreer Totalen
          </button>
          <button mat-raised-button (click)="downloadChecklistReport()">
            <mat-icon>save_alt</mat-icon>Rapport
          </button>
        </mat-card-content>
      </mat-card>

      <mat-card class="management-card">
        <mat-card-title>Print</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/print/promesse">
            <mat-icon>print</mat-icon>Promesse
          </button>
        </mat-card-content>
      </mat-card>

    </mat-card-content>
  </mat-card>

  <mat-card class="category-card">
    <mat-card-title>Productie</mat-card-title>
    <mat-card-content class="category-card-content">

      <mat-card class="management-card">
        <mat-card-title>Potproductie</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/voorraad/potten/oppot-opdrachten">
            <mat-icon fontIcon="note_add"></mat-icon>Oppot gereed
          </button>
          <button mat-raised-button routerLink="voorraad/potten/oppot-wegzetten">
            <mat-icon fontIcon="local_shipping"></mat-icon>Wegzetten gereed
          </button>
        </mat-card-content>
      </mat-card>

      <mat-card class="management-card">
        <mat-card-title>Vollegrond productie</mat-card-title>
        <mat-card-content class="management-card-content">
          <button mat-raised-button routerLink="/voorraad/vollegrond/uitplant-opdrachten">
            <mat-icon fontIcon="note_add"></mat-icon>Uitplanten gereed
          </button>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
