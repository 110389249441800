import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SalesOrder} from '../../sales-order';
import {SalesOrderitem} from '../../sales-orderitem';

@Component({
  selector: 'app-change-bon-dialog',
  templateUrl: './change-bon-dialog.component.html',
  styleUrls: ['./change-bon-dialog.component.scss']
})
export class ChangeBonDialogComponent implements OnInit {

  order: SalesOrder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<boolean>
  ) {
    this.order = data.order;
  }

  ngOnInit(): void {
  }

  agree() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

}
