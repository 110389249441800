export class SalesOrderitem {

  id: number;
  version: number;
  articleId: number;
  salesOrderId: number;
  stockId: number;
  supplierId: number;
  reservationItemId: number;
  unitSizeId: number;
  articleName: string;
  orderAmount: number;
  deliverAmount: number;
  salesPrice: number;
  buyPrice: number;
  location: string;
  confirmedToCustomer: boolean ;
  orderedFromSupplier: boolean;
  isSearched: boolean;
  remarks: string;
  comments: string;
  isSteekLabelPrinted: boolean;
  isPakLabelPrinted: boolean;
  neededLabels: number;
  scannedVml: number;
  scannedVmh: number;
  scannedMerged: number;
  voorraadnr: number;
  voorraadvnr: number;
  dBaseAfroepNr: number;
  mustBeChecked: boolean;
  allLabelsScanned: boolean;
}
