import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SalesOrderService} from '../../sales-order.service';
import {SalesOrderitem} from '../../sales-orderitem';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-scan-print-item',
  templateUrl: './scan-print-item.component.html',
  styleUrls: ['./scan-print-item.component.scss']
})
export class ScanPrintItemComponent implements OnInit {

  @Input() index: number;
  @Input() barcode: number;
  @Output() remove = new EventEmitter<undefined>();

  salesOrderitem: SalesOrderitem;
  isLoadingItem = new BehaviorSubject(true);
  isLoadingItem$ = this.isLoadingItem.asObservable();
  errorMessage: string;

  constructor(private checklistService: SalesOrderService) {
  }

  async ngOnInit() {
    return this.loadItem(this.barcode);
  }

  async loadItem(barcode: number) {
    try {
      this.salesOrderitem = await this.checklistService.findSalesOrderItem(barcode).toPromise();
    } catch (error) {
      this.errorMessage = 'Fout bij ophalen van ' + barcode;
    } finally {
      this.isLoadingItem.next(false);
    }
  }

  removeItem(): void {
    this.remove.emit();
  }

}
