import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PlugStock} from '../plugStock';
import {PlugStockService} from '../plug-stock.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {MatDialog} from '@angular/material/dialog';
import {AddNewPlugStockDialogComponent} from '../add-new-stock-dialog/add-new-plug-stock-dialog.component';

@Component({
  selector: 'app-plug-stock-search',
  templateUrl: './plug-stock-search.component.html',
  styleUrls: ['./plug-stock-search.component.scss']
})
export class PlugStockSearchComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  displayedColumns = ['plantName', 'location', 'originalAmount', 'rest', 'pottedDate', 'readyForPotting'];
  dataSource: MatTableDataSource<PlugStock> = new MatTableDataSource<PlugStock>();
  loadingSubject = new BehaviorSubject<boolean>(false);
  searchTermSubject = new BehaviorSubject<string>('');
  loading$ = this.loadingSubject.asObservable();
  noResultsFound = false;
  searchTerm: string;
  scrollingElement: Element;

  constructor(
    private stekService: PlugStockService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.searchTerm = this.route.snapshot.paramMap.get('searchTerm');
    this.searchTermSubject.next(this.searchTerm);
    this.scrollingElement = document.querySelector('mat-sidenav-content');
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public async onAttach() {
    if (this.searchTerm) {
      this.findStek(this.searchTerm).subscribe(voorraad => {
        this.dataSource.data = voorraad;
        super.onAttach();
      });
    } else {
      this.searchInput.nativeElement.focus();
    }
  }

  findStek(searchTerm: string): Observable<PlugStock[]> {
    if (!searchTerm) {
      return of([]);
    }
    this.loadingSubject.next(true);
    return this.stekService.findByCodeOrName(searchTerm).pipe(
      catchError(() => of([])),
      map(stek => stek.filter(item => item.total > 0)),
      tap(result => this.noResultsFound = result.length === 0),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      filter(str => str && str.length >= 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(search => this.findStek(search)),
    ).subscribe(voorraad => this.dataSource.data = voorraad);
  }

  ngOnDestroy() {
    this.searchTermSubject.unsubscribe();
    this.loadingSubject.unsubscribe();
  }

  openAddStockDialog() {
    this.dialog.open(AddNewPlugStockDialogComponent, {
      width: '90%',
      height: '90%',
    })
      .afterClosed().subscribe(() => this.onAttach());
  }
}

