import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ScannerComponent} from '../../../common/scanner/scanner.component';
import {QuaggaJSResultObject} from '@ericblade/quagga2';
import {Router} from '@angular/router';
import {SalesOrderService} from '../../sales-order.service';
import {BeepService} from '../../../_services/beep.service';

@Component({
  selector: 'app-scan-bon-dialog',
  templateUrl: './scan-bon-dialog.component.html',
  styleUrls: ['./scan-bon-dialog.component.scss']
})
export class ScanBonDialogComponent implements AfterViewInit {

  scannerStarted = false;

  @ViewChild(ScannerComponent) scanner: ScannerComponent;

  constructor(
    private dialogRef: MatDialogRef<number>,
    private router: Router,
    private checkListService: SalesOrderService,
    private beepService: BeepService) {
  }

  ngAfterViewInit(): void {
    this.scanner.start();
  }

  onStarted(started: boolean): void {
    this.scannerStarted = started;
  }

  onScanSuccess(result: QuaggaJSResultObject) {
    this.beepService.plop();
    window.navigator.vibrate(200);
    this.scanner.stop();
    const itemSleutel = +result.codeResult.code;
    this.checkListService.findSalesOrderIdByItemSleutel(itemSleutel).subscribe({
      next: id => this.router.navigate(['afchecklijst', id], {queryParams: {orderItemId: itemSleutel}}),
      error: error => this.scanner.start(),
      complete: () => this.dialogRef.close(result.codeResult.code)
    });
  }
}
